/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 66.14.0-v202503272212-CD
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise } from 'axios';
import { getAxiosInstance, SeeqAxiosRequestConfig } from '@/requests/axios.utilities';
import { isNil, omitBy } from 'lodash';
import * as qs from 'qs';
import * as models from '../model/models';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';

export class sqProjectsApiClass {
 
   constructor() {}

  /**
   * @summary Archive a project
   * @param {string} id - ID of the project to archive
   */
  public archiveProject(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/projects/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ArchiveOutputV1>;
  }

  /**
   * @summary Create a project
   */
  public createProject(
    body: models.ProjectInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/projects`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ProjectOutputV1>;
  }

  /**
   * @summary Get a project
   * @param {string} id - ID of the project to retrieve
   */
  public getProject(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/projects/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ProjectOutputV1>;
  }

  /**
   * @summary List projects
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results
   */
  public getProjects(
    {
      offset,
      limit
    } : {
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/projects`,

      params: omitBy({
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.GetProjectsOutputV1>;
  }

  /**
   * @summary Update a project
   * @param {string} id - The Seeq ID for the project
   */
  public putProject(
    body: models.ProjectInputV1,
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/projects/${encodeURIComponent(String(id))}`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ProjectOutputV1>;
  }

  /**
   * @summary Schedule a Notebook within a Project to run on a set interval
   * @param {string} id - ID of the Project containing the Notebook to schedule
   */
  public scheduleNotebook(
    body: models.ScheduledNotebookInputV1,
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/projects/${encodeURIComponent(String(id))}/scheduleNotebook`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ScheduledNotebookOutputV1>;
  }

  /**
   * @summary Archive Scheduled Notebook(s) within a Project so that it will no longer run
   * @param {string} id - ID of the Project containing the Notebook to unschedule
   * @param {string} filePath - The path to the Notebook file. Using &#x27;*&#x27; will unschedule all Notebooks for this Project, regardless of their label
   * @param {string} [label] - The label for the Notebook schedule. Using &#x27;*&#x27; will unschedule all labels for this filePath
   */
  public unscheduleNotebook(
    {
      id,
      filePath,
      label
    } : {
      id: string,
      filePath: string,
      label?: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    if (isNil(filePath)) {
      throw new Error("'filePath' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/projects/${encodeURIComponent(String(id))}/scheduleNotebook`,

      params: omitBy({
        ['filePath']: filePath,
        ['label']: label
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ScheduledNotebookListOutputV1>;
  }

}


export const sqProjectsApi = new sqProjectsApiClass();
