/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 66.14.0-v202503272212-CD
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise } from 'axios';
import { getAxiosInstance, SeeqAxiosRequestConfig } from '@/requests/axios.utilities';
import { isNil, omitBy } from 'lodash';
import * as qs from 'qs';
import * as models from '../model/models';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';

export class sqAuthApiClass {
 
   constructor() {}

  /**
   * @summary Get a collection of authentication providers
   * @param {Array<string>} [includeAuthProviderIds] - The ID(s) of the authentication provider to include even if it is hidden. For multiple value, use comma delimiter.
   */
  public getAuthProviders(
    {
      includeAuthProviderIds
    } : {
      includeAuthProviderIds?: Array<string>
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/auth/providers`,

      params: omitBy({
        ['includeAuthProviderIds']: includeAuthProviderIds
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.AuthProvidersOutputV1>;
  }

  /**
   * @summary Get a valid CSRF token for this authentication session
   */
  public getCsrf(
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/auth/csrf`,

      ...extraHttpRequestConfig
      }) as AxiosPromise;
  }

  /**
   * @summary Login
   */
  public login(
    body: models.AuthInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/auth/login`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.UserOutputV1>;
  }

  /**
   * @summary Invalidate the session to be completely unusable
   */
  public logout(
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/auth/logout`,

      ...extraHttpRequestConfig
      }) as AxiosPromise;
  }

  /**
   * @summary Request password reset
   */
  public requestPasswordReset(
    body: models.RequestPasswordResetInput,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/auth/request-password-reset`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise;
  }

  /**
   * @summary Perform password reset
   */
  public resetPassword(
    body: models.PasswordResetInput,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/auth/reset-password`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise;
  }

  /**
   * @summary Check that the authentication and CSRF tokens present in the request are valid
   */
  public validate(
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/auth/validate`,

      ...extraHttpRequestConfig
      }) as AxiosPromise;
  }

}


export const sqAuthApi = new sqAuthApiClass();
